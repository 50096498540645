// Titles
h1 {
  font-family: $fontHelvetica;
  font-style: normal;
  font-weight: 600;
  font-size: 90px;
  line-height: 1.1em;
  letter-spacing: 0.02em;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 64px;
  }
}

h2 {
  font-family: $fontHelvetica;
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 1.1em;
  letter-spacing: 0.02em;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 48px;
  }
}

h3 {
  font-family: $fontGaramond;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 1em;
  letter-spacing: 0.02em;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 24px;
  }
}

h4 {
  font-family: $fontGaramond;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1em;
  letter-spacing: 0.02em;
  margin: 0;
}

// Paragraphs

.p1 {
  font-family: $fontHelvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.1em;
  letter-spacing: 0.02em;

  @media (max-width: 767px) {
    font-size: 28px;
  }
}

.p2 {
  font-family: $fontHelvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 1.1em;
  letter-spacing: 0.02em;
}

.p3 {
  font-family: $fontHelvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.4em;
  letter-spacing: 0.02em;
}

.p4 {
  font-family: $fontHelvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4em;
  letter-spacing: 0.02em;
}
