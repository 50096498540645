.talent-container {
   width: 100%;
   padding: 0 45px;

  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .talent-name {
    max-width: 600px;
    font-size: 72px;
    font-weight: 600;
    line-height: 1;
    font-family: $fontHelvetica;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 20px;

    @media (max-width: 1023px) {
      margin-bottom: 15px;
    }

    @media (max-width: 767px) {
      font-size: 48px;
    }
  }

   .talent-top {
      display: flex;
      flex-direction: row;
      gap: 40px;

      .column-left {
         display: flex;
         flex-direction: column;
         width: calc(50% - 20px);

         @media (max-width: 1023px) {
            width: 100%;
         }

         .talent-job {
            font-size: 36px;
            line-height: 36px;
            font-weight: 600;
            font-family: $fontGaramond;
            font-style: normal;
            margin-top: 0;
            margin-bottom: 50px;

            @media (max-width: 1023px) {
               margin-bottom: 30px;
            }

            @media (max-width: 767px) {
               font-size: 18px;
               line-height: 20px;
            }
         }

         .talent-image-container-mobile {
            display: none;
            max-width: 500px;
            margin: 0 auto 30px;
            width: 100%;
            border: 1px solid $darkBlue;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }

            @media (max-width: 1023px) {
               display: flex;
            }
         }

         .talent-description {
            font-size: 22px;
            line-height: 31px;
            font-family: $fontHelvetica;
            font-style: normal;
            max-width: 600px;

            @media (max-width: 1023px) {
               max-width: none;
            }

            @media (max-width: 767px) {
               font-size: 18px;
               line-height: 25px;
            }
         }
      }

      .column-right {
         display: flex;
         flex-direction: column;
         width: calc(50% - 20px);
          align-items: flex-start;

         @media (max-width: 1023px) {
            display: none;
         }

         .talent-image-container {
            display: flex;
            max-width: 100%;
            max-height: 100%;
            border: 1px solid $darkBlue;
            margin-left: auto;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }

      }
   }

   .talent-achievements-container {
      margin: 125px 0 75px;

      @media (max-width: 767px) {
         margin: 60px 0 80px;
      }

      .achievement-title {
        font-family: $fontGaramond;
         font-size: 56px;
         line-height: 62px;
         font-weight: 600;
         margin-bottom: 75px;

         @media (max-width: 767px) {
            font-size: 48px;
            line-height: 48px;
            font-weight: 600;
            margin-bottom: 30px;
         }
      }

      .achievements-galery {
         flex-grow: 1;
         display: flex;
         flex-wrap: wrap;

         @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
         }

         .project-item {
            width: calc(100% / 3);
            position: relative;
            display: flex;

            @media (max-width: 767px) {
            width: 100%;
            margin: 0 0 15px;
            }

            &:hover {
               .project-info {
                  opacity: 1;
                  pointer-events: auto;

                  .info-title {
                     opacity: 1;
                     transform: translateY(0);
                  }
               }
            }

            .project-thumbnail {
            width: 100%;
            }

            .project-info {
               position: absolute;
               inset: 0;
               background: rgba(0, 0, 0, 0.4);
               padding: 33px 25px 33px 33px;
               display: flex;
               flex-direction: column;
               align-items: flex-end;
               opacity: 0;
               transition: opacity 0.2s ease-in-out;
               pointer-events: none;

               @media (max-width: 767px) {
                  padding: 33px 20px 33px 33px;
               }

               .info-title {
                  width: 100%;
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: center;
                  font-family: $fontHelvetica;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 22px;
                  line-height: 1em;
                  color: $grey25;
                  transform: translateY(30px);
                  opacity: 0;
                  transition: all 0.2s ease-in-out;

                  .item-client {
                     margin-top: 7px;
                     font-style: normal;
                     font-weight: 400;
                     font-size: 16px;
                     line-height: 1.4em;
                     text-transform: uppercase;
                  }
               }

               .info-link {
                  display: none;
                  @extend .inline-link;
                  @extend .light;

                  @media (max-width: 767px) {
                     display: flex;
                  }
               }
            }
         }
      }
   }

  &.services {
    display: flex;
    flex-direction: column;
    gap: 100px;
    margin-bottom: 100px;

    .talent-top {
      align-items: center;

      &.reversed {
        flex-direction: row-reverse;
      }

      .column-left .talent-description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }

      &:not(:has(.column-right > .talent-image-container)) {
        .column-left {
          width: calc(70% - 20px);

          .talent-description {
            max-width: 100%;
          }
        }

        .column-right {
          width: calc(30% - 20px);
        }
      }
    }
  }

  .contact-form {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    h3 {
      font-size: 24px;
    }

    input, textarea {
      width: 100%;
      padding: 10px 20px;
      border-radius: 4px;
      border: 1px solid $darkBlue;
      background-color: $light;
      outline: none;
      appearance: none;
      font-size: 18px;
      margin-bottom: 20px;
    }

    .alert.success {
      width: 100%;
      padding: 10px 20px;
      border-radius: 4px;
      color: $colorSuccess;
      background-color: color-mix(in lab, $colorSuccess 25%, $light 75%);
      margin-bottom: 20px;
    }

    .button {
      min-width: 200px;
    }
  }
}
