// --------------------------------------------------
// Variables
// --------------------------------------------------

// Import Web Fonts
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

// Fonts
$fontGaramond: 'EB Garamond', serif;
$fontHelvetica: 'Helvetica Neue', sans-serif;

$fontDegular: $fontHelvetica;
$fontInterface: $fontHelvetica;

// Accents Colors
$darkBlue: #454241;
$darkBlue70: #454241;
$lightGrey: #C8C7C8;
$grey50: #C8C7C8;
$grey25: #F4F4F3;
$turquoise: #454242;
//$turquoise: #2c3e99;

$colorAccent1: $turquoise;

// Texts
$dark: $darkBlue;
$grey: $lightGrey;
$light: #FFFFFF;

$colorSuccess: #00966D;
$colorError: #FF564A;
