.home-about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 66px 50px 125px;

  @media (max-width: 767px) {
    padding: 60px 15px;
  }

  .about-heading {
    width: 100%;
    max-width: 890px;
    font-family: $fontGaramond;
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
    line-height: 1em;
    letter-spacing: 0.02em;
    color: $darkBlue;
    margin: 0 0 45px;
    padding-bottom: 45px;
    border-bottom: 1px solid $darkBlue;

    @media (max-width: 767px) {
      font-size: 48px;
      line-height: .9em;
      margin-bottom: 15px;
      padding: 0;
      border: none;
    }

    &:is(h2) {
      font-size: 48px;
      padding-bottom: 0;
      border-bottom: none;

      @media (max-width: 767px) {
        font-size: 32px;
      }
    }
  }

  .about-text {
    width: 100%;
    max-width: 800px;
    @extend .p3;
    margin: 0;
  }

  .about-logos {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px 30px;

    .about-logo {
      width: 100%;
      max-width: 250px;
      aspect-ratio: 3/1;
      display: flex;

      .about-logo-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}
