.projects-listing {
  width: 100%;
  display: flex;
  align-items: stretch;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  .filters-wrapper {
    flex-shrink: 0;
    position: relative;

    @media (max-width: 767px) {
      display: none;
    }

    .filters-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 100px 45px 45px;

      .filters-heading {
        display: flex;
        align-items: flex-start;
        margin: 0 0 130px;

        @media (max-width: 1023px) {
          margin: 0 0 50px;
        }

        .title {
          font-family: $fontHelvetica;
          font-style: normal;
          font-weight: 600;
          font-size: 56px;
          line-height: 1.1em;
          letter-spacing: 0.02em;
          color: $darkBlue;

          @media (max-width: 1023px) {
            font-size: 38px;
          }
        }

        .number {
          font-family: $fontHelvetica;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.1em;
          letter-spacing: 0.02em;
          color: $darkBlue;
          padding-top: 10px;

          @media (max-width: 1023px) {
            padding-top: 5px;
          }
        }
      }

      .filters-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 33px;

        @media (max-width: 1023px) {
          gap: 24px;
        }

        .filter-item {
          font-family: $fontHelvetica;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 1.1em;
          letter-spacing: 0.02em;
          color: $darkBlue;

          @media (max-width: 1023px) {
            font-size: 18px;
          }

          &.is-active {
            font-weight: 700;
            color: $turquoise;
          }
        }
      }
    }
  }

  .mobile-filters-container {
    display: none;

    @media (max-width: 767px) {
      display: flex;
      padding: 15px 15px 40px;
      flex-direction: column;
      align-items: flex-start;

      .filters-heading {
        font-family: $fontHelvetica;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 1em;
        letter-spacing: 0.02em;
        color: $darkBlue;
        margin: 0 0 32px;
      }

      .mobile-filters {
        width: 100%;
        background-color: $grey50;
        padding: 5px 85px 5px 15px;
        border: 1px solid $darkBlue;
        appearance: none;
        outline: none;
        font-family: $fontHelvetica;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4em;
        color: $darkBlue;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MiA0OCI+PHBhdGggZmlsbD0iI0QxRDFDRiIgc3Ryb2tlPSIjMjEyQjNEIiBkPSJNLjUuNWg3MXY0N0guNXoiLz48bWFzayBpZD0iYSIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMjQiIHk9IjEyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiPjxwYXRoIGZpbGw9IiNEOUQ5RDkiIGQ9Ik0yNCAxMmgyNHYyNEgyNHoiLz48L21hc2s+PGcgbWFzaz0idXJsKCNhKSI+PHBhdGggZD0ibTM2IDI3LjQtNi02IDEuNC0xLjQgNC42IDQuNiA0LjYtNC42IDEuNCAxLjQtNiA2WiIgZmlsbD0iIzIxMkIzRCIvPjwvZz48L3N2Zz4=");
        background-repeat: no-repeat;
        background-position: center right -1px;
        background-size: auto calc(100% + 2px);
      }
    }
  }

  .items-container {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    .project-item {
      width: calc(100% / 3);
      position: relative;
      display: flex;

      @media (max-width: 767px) {
        width: calc(100% - 30px);
        margin: 0 0 15px;
      }

      &:first-child {
        width: 100%;

        @media (max-width: 767px) {
          margin: 0 0 20px;
        }

        @media (min-width: 768px) {
          .project-info {
            padding: 60px 60px 125px;
            justify-content: flex-end;

            .info-title {
              justify-content: flex-end;
              font-size: 36px;

              .item-client {
                margin-top: 10px;
                font-size: 28px;
                line-height: 110%;
              }
            }
          }
        }
      }

      &:hover {
        .project-info {
          opacity: 1;
          pointer-events: auto;

          .info-title {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      .project-thumbnail {
        width: 100%;
      }

      .project-info {
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.4);
        padding: 33px 25px 33px 33px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        pointer-events: none;

        @media (max-width: 767px) {
          padding: 33px 20px 33px 33px;
        }

        .info-title {
          width: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          font-family: $fontHelvetica;
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 1em;
          color: $grey25;
          transform: translateY(30px);
          opacity: 0;
          transition: all 0.2s ease-in-out;

          .item-client {
            margin-top: 7px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.4em;
            text-transform: uppercase;
          }
        }

        .info-link {
          display: none;
          @extend .inline-link;
          @extend .light;

          @media (max-width: 767px) {
            display: flex;
          }
        }
      }
    }
  }
}
