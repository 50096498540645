.home-hero {
  width: 100%;
  display: flex;
  flex-direction: column;

  .flickity-button {
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    width: 64px;
    height: 64px;
    padding: 0;
    margin: 0;
    display: flex;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NCA2NCI+PGNpcmNsZSBvcGFjaXR5PSIuNSIgY3g9IjMyIiBjeT0iMzIiIHI9IjMyIiBmaWxsPSIjRjRGNEYzIi8+PG1hc2sgaWQ9ImEiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjIwIiB5PSIyMCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjRDlEOUQ5IiBkPSJNMjAgMjBoMjR2MjRIMjB6Ii8+PC9tYXNrPjxnIG1hc2s9InVybCgjYSkiPjxwYXRoIGQ9Im0zMiA0MC0xLjQyNS0xLjQgNS42LTUuNkgyNHYtMmgxMi4xNzVsLTUuNi01LjZMMzIgMjRsOCA4LTggOFoiIGZpbGw9IiMyMTJCM0QiLz48L2c+PC9zdmc+");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      display: none;
    }

    &.previous {
      right: initial;
      left: 50px;
      transform: translateY(-50%) rotate(180deg);
    }

    svg {
      display: none;
    }

    &:disabled {
      opacity: 0 !important;
    }
  }

  .flickity-page-dots {
    display: none;
    bottom: 80px;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .dot {
      width: 9px;
      height: 9px;
      border-radius: 9px;
      background-color: $lightGrey;
      opacity: 0.7;
      margin: 0;

      &.is-selected {
        opacity: 1;
      }
    }

    @media (max-width: 767px) {
      display: flex;
    }
  }

  .hero-item {
    position: relative;
    width: 100%;
    padding-top: calc(100vh - 122px);
    display: flex;

    @media (max-width: 767px) {
      padding-top: calc(100vh - 96px);
    }

    &.is-selected {
      .item-content {
        opacity: 1;
        transform: none;
      }
    }

    .image-wrapper {
      position: absolute;
      inset: 0;
      z-index: -999;
      pointer-events: none;
      overflow: hidden;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .video-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -999;
      pointer-events: none;
      overflow: hidden;

      // &:before {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   left: 0;
      //   background-color: rgba($dark, .55);
      //   z-index: 5;
      // }

      iframe {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .item-content {
      position: absolute;
      left: 50px;
      right: 50px;
      bottom: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
      transform: translateY(50px);
      opacity: 0;
      transition: all .7s ease-in-out;
      transition-delay: .2s !important;

      @media (max-width: 767px) {
        left: 15px;
        right: 15px;
      }

      .item-heading {
        margin: 0;
        color: $light;
      }

      .item-subtitle {
        @extend .p3;
        color: $grey25;
        margin: 0;
      }
    }
  }
}
