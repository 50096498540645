.project-description {
  padding: 126px 50px;
  width: 100%;
  max-width: calc(917px + 100px);
  @extend .p1;
  margin: 0;

  @media (max-width: 767px) {
    padding: 60px 15px;
  }
}

.project-content {
  width: 100%;
  display: flex;
  flex-direction: column;

  .images-line {
    width: 100%;
    display: flex;
    align-items: stretch;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .image-duo {
      width: 50%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .image-solo {
    width: 100%;
  }
}

.project-credits {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 45px;
  padding: 126px 45px 180px;

  @media (max-width: 1023px) {
    padding: 80px 45px;
  }

  @media (max-width: 767px) {
    padding: 40px 15px 80px;
    gap: 30px;
  }

  .credit-item {
    width: calc((100% - 135px) / 4);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 1240px) {
      width: calc((100% - 90px) / 3);
    }

    @media (max-width: 1023px) {
      width: calc((100% - 45px) / 2);
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    .credit-type {
      font-family: $fontGaramond;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.1em;
      letter-spacing: 0.02em;
      color: $darkBlue;
      margin: 0 0 9px;
    }

    .credit-name {
      font-family: $fontHelvetica;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 1.1em;
      letter-spacing: 0.02em;
      color: $darkBlue;
      margin: 0;
    }
  }
}
