.talent-listing-container {
   position: relative;
   width: 100%;
   margin: 150px 0;

   @media (max-width: 767px) {
      margin: 30px 0 100px;
   }

   .talent-block {
      display: flex;
      position: relative;
      align-items: center;
      width: 100%;
      height: 100px;
      margin-top: -1px;
      cursor: pointer;
      overflow: visible;

      @media (max-width: 767px) {
         height: 70px;
      }

      &:hover {
         background-color: $colorAccent1;
         color: $light;

         &::after {
            display: none;
         }

         h3 {
            z-index: 20;
         }

         .image-container {
            position: absolute;
            display: block;
            top: -149px;
            right: 45px;
            z-index: 10;
            width: 420px;
            height: 450px;
            border: 1px solid $darkBlue;
            pointer-events: none;
            transition: width .3s ease;

            @media (max-width: 767px) {
               display: none;
            }

            img {
               display: block;
               height: 100%;
               width: 100%;
               object-fit: cover;
               object-position: right;
            }
         }
      }

      &::after {
         content: "";
         position: absolute;
         bottom: 0;
         left: 45px;
         width: calc(100% - 90px);
         height: 1px;
         background-color: $darkBlue;

         @media (max-width: 767px) {
            left: 20px;
            width: calc(100% - 40px);
         }
      }

      h3 {
         position: relative;
         font-size: 90px;
         font-weight: 600;
         line-height: 100px;
         margin: 0 45px 10px;
         width: 100%;

         @media (max-width: 767px) {
            font-size: 36px;
            line-height: 40px;
            margin: 0 20px 10px;
         }

      }

      .image-container {
         display: block;
         width: 0;
         overflow: hidden;
      }
   }
}
