.home-news {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 50px;
  margin-bottom: 165px;

  @media (max-width: 767px) {
    padding: 0 15px;
    margin-bottom: 100px;
  }

  .news-heading {
    width: 100%;
    margin-bottom: 50px;
    padding-bottom: 30px;
    border-bottom: 1px solid $darkBlue;

    @media (max-width: 767px) {
      margin-bottom: 40px;
      padding-bottom: 15px;
    }
  }

  .news-content {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 25px;

    @media (max-width: 1023px) {
      gap: 40px;
    }

    .news-item {
      width: calc((100% - 50px) / 3);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      margin-bottom: 35px;

      @media (max-width: 1023px) {
        width: calc((100% - 25px) / 2);
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 0;
      }

      .item-heading {
        margin: 0 0 10px;
      }

      .item-infos {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid $darkBlue;
        margin-bottom: 30px;

        .item-subtitle {
          @extend .p3;
          margin: 0;
        }

        .item-link {
          @extend .inline-link;
        }
      }

      .item-image-container {
        width: 100%;
        display: flex;
        border: 1px solid $darkBlue;

        .item-image {
          width: 100%;
        }
      }

      .item-tag {
        font-family: $fontInterface;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.4em;
        color: $darkBlue;
        padding: 0 25px 4px;
        background: #dcdbdb;
        border-radius: 40px;
        margin-top: 23px;

        @media (max-width: 767px) {
          margin-top: 10px;
          font-size: 20px;
        }
      }
    }
  }
}
